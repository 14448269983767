/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-lines */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth';
import {
    get, isEmpty
} from 'lodash';
import PropTypes from 'prop-types';
import {
    cloneElement, lazy,
    Suspense
} from 'react';

import Breadcrumbs from 'Component/Breadcrumbs';
import CategoryDetails from 'Component/CategoryDetails';
import CategoryItemsCount from 'Component/CategoryItemsCount';
import CategoryProductList from 'Component/CategoryProductList';
import CmsProductSlider from 'Component/CmsProductSlider/CmsProductSlider';
import ContentWrapper from 'Component/ContentWrapper';
import Html from 'Component/Html';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import RackFamilyBlock from 'Component/RackFamilyBlock';
import {
    BREADCRUMBS_TYPE
} from 'Component/Router/Router.config';
import Sidebar from 'Component/SidebarMenu';
import WhyUsBlock from 'Component/WhyUsBlock';
import {
    CategoryPage as SourceCategoryPage
} from 'SourceRoute/CategoryPage/CategoryPage.component';
import { isCrawler, isSSR } from 'Util/Browser';
import { isUsaStore } from 'Util/FrontRunner/Store';
import { getSalesPromotion, getSubscriberDrivePromo } from 'Util/FrontRunner/Store/Promotions';

// import { isB2BUser } from 'Util/Helper';
import {
    GRID_LAYOUT
} from './CategoryPage.config';

import './CategoryPage.style';

export const CategoryFilterOverlay = lazy(() => import(
    /* webpackMode: "lazy", webpackChunkName: "overlays-category" */ 'Component/CategoryFilterOverlay'
));

/** @namespace Pwa/Route/CategoryPage/Component/CategoryPage */
export class CategoryPage extends SourceCategoryPage {
    static propTypes = {
        isVehiclesPage: PropTypes.bool.isRequired,
        sideMenuData: PropTypes.any
    };

    [BREADCRUMBS_TYPE] = [
        {
            component: <Breadcrumbs />,
            position: 30
        }
    ];

    renderItemsOfType(type) {
        return this.getSortedItems(type)
            .map(({ position, component }) => cloneElement(component, { key: position }));
    }

    getSortedItems(type) {
        return this[type].sort(
            (a, b) => a.position - b.position
        ).filter(
            (entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');
                    return false;
                }

                return true;
            }
        );
    }

    renderPromoImage() {
        // Check if B2B customer
        const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

        if (!isUsaStore() || isB2B) {
            return null;
        }

        const {
            category: { id }
        } = this.props;

        // Is Sale Promotion Active?
        // show deals link when sale period is active
        const salesPromotion = getSalesPromotion();
        const salesPromotionActive = salesPromotion?.active ?? false;

        // Check if category id is one of (4, 6, 7, 8, 160, 161, 78, 84, 90, 171, 119, 117, 174) - Racks
        // if ([3, 4, 6, 7, 8, 160, 161, 78, 84, 90, 171, 119, 117].includes(id) && salesPromotionActive) {
        //     return (
        //         <div className="category-promo">
        //             <picture>
        //                 <source media="(max-width: 800px)" srcSet="https://www.frontrunneroutfitters.com/media/landing-pages/promotions/usa/2024_4jul_deal1_mobile.jpg" />
        //                 <source media="(min-width: 801px)" srcSet="https://www.frontrunneroutfitters.com/media/landing-pages/promotions/usa/2024_4jul_deal1.jpg" />
        //                 <img src="https://www.frontrunneroutfitters.com/media/landing-pages/promotions/usa/2024_4jul_deal1.jpg" alt="Buy any Rack and get a Wolf Pack Pro, Stratchits and Tie Down Rings FREE" fetchpriority="high" width={ 1920 } height={ 500 } />
        //             </picture>
        //         </div>
        //     );
        // }

        // Pro bed racks
        // if ([174].includes(id) && salesPromotionActive) {
        //     return (
        //         <div className="category-promo">
        //             <picture>
        //                 <source media="(max-width: 800px)" srcSet="https://www.frontrunneroutfitters.com/media/landing-pages/promotions/usa/2024_4jul_deal2_mobile.jpg" />
        //                 <source media="(min-width: 801px)" srcSet="https://www.frontrunneroutfitters.com/media/landing-pages/promotions/usa/2024_4jul_deal2.jpg" />
        //                 <img src="https://www.frontrunneroutfitters.com/media/landing-pages/promotions/usa/2024_4jul_deal2.jpg" alt="Buy a Pro Bed Rack Kit and get 2 Wolf Pack Pros FREE" fetchpriority="high" width={ 1920 } height={ 500 } />
        //             </picture>
        //         </div>
        //     );
        // }

        return null;
    }

    renderCategoryProductList() {
        const {
            category,
            isInfoLoading = false,
            filter,
            search,
            selectedSort,
            totalPages = 0,
            selectedFilters,
            isMatchingListFilter,
            isCurrentCategoryLoaded,
            isMatchingInfoFilter,
            isVehiclesPage,
            isDataLoading = false
        } = this.props;

        // const { activeLayoutType } = this.state;
        const baseUrl = window.location.pathname;
        if (baseUrl.includes('/vehicle/') || baseUrl.includes('?find=')) {
            let showLoader = false;

            if (isDataLoading || isInfoLoading) {
                showLoader = true;
            }

            return (
                <div
                  block="CategoryPage"
                  elem="ProductListWrapper"
                  mods={ { isPrerendered: isSSR() || isCrawler() } }
                >
                { (totalPages > 0) && (this.renderItemsCount(true)) }
                    <Loader isLoading={ showLoader } />
                    <CategoryProductList
                      filter={ filter }
                      search={ search }
                      sort={ selectedSort }
                      selectedFilters={ selectedFilters }
                      isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                      isMatchingListFilter={ isMatchingListFilter }
                      isMatchingInfoFilter={ isMatchingInfoFilter }
                      layout={ GRID_LAYOUT }
                      isVehiclesPage={ isVehiclesPage }
                    />
                </div>
            );
        }

        if (!this.displayProducts()) {
            return null;
        }

        return (
            <div
              block="CategoryPage"
              elem="ProductListWrapper"
              mods={ { isPrerendered: isSSR() || isCrawler() } }
            >
                { this.renderItemsCount(true) }
                { this.renderPromoImage() }
                <CategoryProductList
                  filter={ filter }
                  search={ search }
                  sort={ selectedSort }
                  selectedFilters={ selectedFilters }
                  isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
                  isMatchingListFilter={ isMatchingListFilter }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  layout={ GRID_LAYOUT }
                  isVehiclesPage={ isVehiclesPage }
                />
            </div>
        );
    }

    getSubTitle=() => {
        let subTitle;
        let strIn = window.location.href;
        let searchTerm = '/vehicle/';
        let searchIndex = strIn.indexOf(searchTerm);
        let strOut = strIn.substr(searchIndex + searchTerm.length);

        let arr = strOut.split('-');
        subTitle = '';
        let i;
        for (i = 0; i < arr.length - 1; i++) {
            subTitle += `${arr[i]} `;
        }
        subTitle = subTitle.replaceAll('%20', ' ');
        subTitle = subTitle.replaceAll('show all', '');
        subTitle = subTitle.trim();

        return subTitle;
    };

    renderCategoryName = () => {
        const { category: { name }, isVehiclesPage } = this.props;
        const title = isVehiclesPage ? __('VEHICLE SPECIFIC GEAR') : name;
        let subTitle;
        if (isVehiclesPage) {
            subTitle = this.getSubTitle();
        }

        const baseUrl = window.location.pathname;
        if (baseUrl.includes('/vehicle/')) {
            return (
                <div className="category_heading_veh">
                     <h1 className="categoryName">
                        { title }
                        { (subTitle) && (
                            <span className="categorySubTitle">
                                { subTitle }
                            </span>
                        ) }
                     </h1>
                </div>

            );
        }

        return null;
    };

    renderCategoryDetails() {
        const {
            category,
            isCurrentCategoryLoaded
        } = this.props;
        const { children = [] } = category;
        const baseUrl = window.location.pathname;

        if (baseUrl.includes('/vehicle/')) {
            return null;
        }

        return (
            <>
            <CategoryDetails
              category={ category }
              isCurrentCategoryLoaded={ isCurrentCategoryLoaded }
            />
            <div className="CategoryName">
                { children.map((data) => (
                    <Link to={ data.url } className="CategoryNameLink">
                        { data.name }
                    </Link>
                )) }
            </div>
            </>
        );
    }

    renderFilterOverlay() {
        const {
            filters,
            selectedFilters,
            isMatchingInfoFilter,
            isVehiclesPage
        } = this.props;

        const { category: { is_anchor } } = this.props;

        if (!this.displayProducts() || isVehiclesPage) {
            return null;
        }
        //  const isMatchiingfilter = (isVehiclesPage) ? true : isMatchingInfoFilter;

        return (
            <Suspense fallback={ this.renderFilterPlaceholder() }>
                <CategoryFilterOverlay
                  availableFilters={ filters }
                  customFiltersValues={ selectedFilters }
                  isMatchingInfoFilter={ isMatchingInfoFilter }
                  isCategoryAnchor={ !!is_anchor }
                />
            </Suspense>
        );
    }

    // Category Promo Banner Summer Deals
    renderCategoryPromoBanner() {
        if (isSignedIn()) {
            // Check if B2B customer
            const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

            // Subscriber Drive
            const subscriberDrivePromo = getSubscriberDrivePromo();
            if (!subscriberDrivePromo.active) {
                return null;
            }

            if (!isB2B) {
                return (
                    <div className="CategoryPage-PromoBanner">
                        <Html content={ subscriberDrivePromo.categoryHeaderText } />
                    </div>
                );
            }

            // if (isB2B) {
            //     return (
            //         <div className="CategoryPage-PromoBanner">
            //             <strong>Labor Day Deals!</strong>
            //             { ' ' }
            //             Get an EXTRA 5% Off Sitewide (Discount applied at cart).
            //             { ' ' }
            //             <span>Promotion ends Sept 7th.</span>
            //         </div>
            //     );
            // }
        }

        return null;
    }

    // Render Products
    renderProducts = (skus) => (
        <CmsProductSlider
          productsskuarray={ skus }
          data={ skus }
          isRelated={ false }
        />
    );

    // Render additional sliders for vehicle page
    renderSlider(type) {
        const sliderConfig = {
            expedition: {
                title: 'Expedition',
                skus: 'RRAC265,AWNI100,RRAC024,JCHO014,JADA014,RRAC168,TBRA037,RRAC025,STRA034,RRAC147'
            },
            camping: {
                title: 'Camping',
                skus: 'TENT031,CHAI007,TBRA017,GBHO012,JCHO013,RRAC178,RRAC165,LIGH179'
            },
            sport: {
                title: 'Sport',
                skus: 'RRAC148,RRAC141,RRAC149,RRAC095,RRAC256,RRAC125,RRAC214,RRAC052,RRAC098'
            },
            storage: {
                title: 'Storage',
                skus: 'SBOX031,SBOX001,SBOX076,RRAC131,RRAC159,SBOX027,SWAG248,SWAG249,VACC100,RRAC257'
            },
            cooking: {
                title: 'Cooking',
                skus: 'KITC041,TBRA051,VACC023,VACC090,RRAC081,TBRA052,RRAC291,RRAC210'
            },
            water: {
                title: 'Water',
                skus: 'WTAN088,RRAC170,WTAN054,WTAN008,WTAN070,WTAN007,RRAC028,WTAN002,WTAN023'
            }
        };

        const baseUrl = window.location.pathname;
        if (baseUrl.includes('/vehicle/')) {
            const config = sliderConfig[type.toLowerCase()];
            return (
                <section className="expSlider">
                    <h2>
                        { __(config.title) }
                    </h2>
                    { this.renderProducts(config.skus) }
                </section>
            );
        }

        return null;
    }

    renderContent() {
        let vehicleCls = '';
        const baseUrl = window.location.pathname;
        if (baseUrl.includes('/vehicle/')) {
            vehicleCls = 'vehiclePgae';
        }

        return (
            <div className={ `${vehicleCls} main_columns_wrap` }>
                <div className="cat_wrap">
                    { this.renderItemsOfType(BREADCRUMBS_TYPE) }
                    { this.renderCategoryDetails() }
                    { this.renderCmsBlock() }
                </div>
                <div className="main_columns">
                    <div className="columns_product">
                        { this.renderCategoryName() }
                        { this.renderMiscellaneous() }
                        { /* { this.renderCategoryPromoBanner() } */ }
                        { this.renderCategoryProductList() }
                        <WhyUsBlock />
                        <RackFamilyBlock />
                        <br />
                        { this.renderSlider('expedition') }
                        { this.renderSlider('camping') }
                        { this.renderSlider('sport') }
                        { this.renderSlider('storage') }
                        { this.renderSlider('cooking') }
                        { this.renderSlider('water') }
                    </div>
                    <div className="sidebar-additional">
                        <div
                          id="menu"
                          mix={ { block: 'SideMenu' } }
                        >
                            { this.renderFilterOverlay() }
                            { this.renderSidebarMenu() }
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderItemsCount(isVisibleOnMobile = false) {
        let { isMatchingListFilter, device, isVehiclesPage = false } = this.props;

        if (isVisibleOnMobile && !device.isMobile) {
            return null;
        }

        if (!isVisibleOnMobile && device.isMobile) {
            return null;
        }
        if (isVehiclesPage) {
            isMatchingListFilter = true;
        }

        return (
            <CategoryItemsCount
              isMatchingListFilter={ isMatchingListFilter }
            />
        );
    }

    renderMiscellaneous() {
        if (!this.displayProducts()) {
            return null;
        }

        return (
            <aside block="CategoryPage" elem="Miscellaneous">
                { this.renderItemsCount() }
                <div
                  block="CategoryPage"
                  elem="LayoutWrapper"
                  mods={ { isPrerendered: isSSR() || isCrawler() } }
                >
                    { /* { this.renderLayoutButtons() } */ }
                    { this.renderCategorySort() }
                </div>
                { this.renderFilterButton() }
            </aside>
        );
    }

    renderSidebarMenu = () => {
        let {
            sideMenuData,
            category: selectCat,
            isVehiclesPage = false,
            updateVehicleProductLoadStatus,
            setProductLoadingStatus,
            cleanProductListItems
        } = this.props;

        const partFinderData = get(this.props, 'location.state.partFinderData', '');
        const vehiclePageSubTitle = get(this.props, 'location.state.vehiclePageSubTitle', '');

        const {
            modelId, yearId, makeId, optionId, categoryId = ''
        } = partFinderData || {};
        let findPath = window.location.href;
        if (findPath.includes('find=')) {
            findPath = findPath.split('find=').pop();
        } else {
            findPath = findPath.split('/').pop();
        }
        let vehicleFinderState = {
            modelId, yearId, makeId, optionId, findPath, categoryId, vehiclePageSubTitle
        };

        if (isEmpty(sideMenuData)) {
            return null;
        }
        if (isEmpty(selectCat)) {
            selectCat = '';
        }
        const { header_content = [{}] } = sideMenuData;

        const [{ shop: [{ category }] }] = header_content;
        return (
            <Sidebar
              sideMenuData={ category }
              category={ selectCat }
              isVehiclesPage={ isVehiclesPage }
              vehicleFinderState={ vehicleFinderState }
              updateVehicleProductLoadStatus={ updateVehicleProductLoadStatus }
              setProductLoadingStatus={ setProductLoadingStatus }
              cleanProductListItems={ cleanProductListItems }
            />
        );
    };

    render() {
        const hideProducts = !this.displayProducts();
        let { isLoading = false } = this.props;
        return (
            <main block="CategoryPage">
                <Loader isLoading={ isLoading } />
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts }
                  } }
                  label="Category page"
                >
                    { this.renderContent() }
                </ContentWrapper>
            </main>
        );
    }
}

export default CategoryPage;
